/*! @name @videojs/vhs-utils @version 2.3.0 @license MIT */
'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var isTypedArray = function isTypedArray(obj) {
  return ArrayBuffer.isView(obj);
};
var toUint8 = function toUint8(bytes) {
  return bytes instanceof Uint8Array ? bytes : new Uint8Array(bytes && bytes.buffer || bytes, bytes && bytes.byteOffset || 0, bytes && bytes.byteLength || 0);
};
var bytesToString = function bytesToString(bytes) {
  if (!bytes) {
    return '';
  }

  bytes = Array.prototype.slice.call(bytes);
  var string = String.fromCharCode.apply(null, toUint8(bytes));

  try {
    return decodeURIComponent(escape(string));
  } catch (e) {// if decodeURIComponent/escape fails, we are dealing with partial
    // or full non string data. Just return the potentially garbled string.
  }

  return string;
};
var stringToBytes = function stringToBytes(string, stringIsBytes) {
  if (stringIsBytes === void 0) {
    stringIsBytes = false;
  }

  var bytes = [];

  if (typeof string !== 'string' && string && typeof string.toString === 'function') {
    string = string.toString();
  }

  if (typeof string !== 'string') {
    return bytes;
  } // If the string already is bytes, we don't have to do this


  if (!stringIsBytes) {
    string = unescape(encodeURIComponent(string));
  }

  return string.split('').map(function (s) {
    return s.charCodeAt(0) & 0xFF;
  });
};
var concatTypedArrays = function concatTypedArrays() {
  for (var _len = arguments.length, buffers = new Array(_len), _key = 0; _key < _len; _key++) {
    buffers[_key] = arguments[_key];
  }

  var totalLength = buffers.reduce(function (total, buf) {
    var len = buf && (buf.byteLength || buf.length);
    total += len || 0;
    return total;
  }, 0);
  var tempBuffer = new Uint8Array(totalLength);
  var offset = 0;
  buffers.forEach(function (buf) {
    buf = toUint8(buf);
    tempBuffer.set(buf, offset);
    offset += buf.byteLength;
  });
  return tempBuffer;
};

exports.bytesToString = bytesToString;
exports.concatTypedArrays = concatTypedArrays;
exports.isTypedArray = isTypedArray;
exports.stringToBytes = stringToBytes;
exports.toUint8 = toUint8;
