/*! @name @videojs/vhs-utils @version 2.3.0 @license MIT */
'use strict';

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var URLToolkit = _interopDefault(require('url-toolkit'));
var window = _interopDefault(require('global/window'));

var resolveUrl = function resolveUrl(baseUrl, relativeUrl) {
  // return early if we don't need to resolve
  if (/^[a-z]+:/i.test(relativeUrl)) {
    return relativeUrl;
  } // if the base URL is relative then combine with the current location


  if (!/\/\//i.test(baseUrl)) {
    baseUrl = URLToolkit.buildAbsoluteURL(window.location && window.location.href || '', baseUrl);
  }

  return URLToolkit.buildAbsoluteURL(baseUrl, relativeUrl);
};

module.exports = resolveUrl;
